import React,{ Suspense, useEffect} from "react";
import { Route, Switch, Redirect, useLocation, useHistory} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CookieConsent from "react-cookie-consent";
import { Spinner } from "reactstrap";
import "./assets/scss/style.scss";
import "./assets/css/styles.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { removeAuthedUser } from "./redux/actions/authedUser";
import { getTimeDifference } from "./utils/utils";
import './internationalization/i18n';
import logo from './assets/img/mysoleas.jpeg';

const AdminLayout = React.lazy(()=>import("layouts/Admin.js"));
const AuthLayout = React.lazy(()=>import("layouts/Auth.js"));

function App(){
    const authedUser = useSelector((state)=>state.authedUser);
    const {isAuthenticated} = authedUser;
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    if(isAuthenticated  && location.pathname.includes('auth')){
        history.replace('/admin/dashboard')
    }
    if(!isAuthenticated && location.pathname.includes('admin')){
        history.replace('/auth/login')
    }
    const tokenDeadline = getTimeDifference(new Date(), authedUser.expiredAt)
    useEffect(()=>{
        //const isTokenExpired = getTimeDifference(new Date(), new Date(authedUser.expiredAt)) < 10000
        const isTokenExpired = tokenDeadline <= 5000
        if(isTokenExpired){
            dispatch(removeAuthedUser())
            history.replace('/auth/login')
        }
        //dispatch(handleGetAuthedUser({token: authedUser.token}))
    },[dispatch, tokenDeadline]) 
    return(
        <>
            <Suspense fallback={<div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}><Spinner color="blue"/></div>}>
            <Switch >
                <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                <Redirect from="/" to='/auth/login'/>
            </Switch>
            </Suspense>
            <FloatingWhatsApp phoneNumber="237698618200" accountName="MYSOLEAS" allowClickAway="true" avatar={logo}/>
            <CookieConsent
                buttonStyle={{ backgroundColor: 'orange'}}
                location='top'
                enableDeclineButton
            >
                SoleasPay used cookies to enhance your experiences on this platform.
            </CookieConsent>
        </>
        
    )
}

export default React.memo(App)
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authedUser from "./authedUser";
import transactions from "./transactions";
import referals from "./referals";
import notifications from "./notifications";
import currencies from "./currencies";
import qrcodes from "./qrcodes";
import credits from "./credits";
import subAccounts from "./subAccounts";
import wallets from "./wallets";
import customers from "./customers";
import invoices from "./invoices";
import whitelist from "./whitelist";
import products from "./products";
import shops from "./shops";
import orders from "./orders";
import coupons from "./coupons";
import subscription from "./subscription";
import categories from "./categories";
import issues from "./issues";
import packages from "./packages";
import channels from "./channels";
import campaigns from "./campaigns";
import partners from "./partners";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["qrcodes"],
};
export default persistReducer(
  rootPersistConfig,
  combineReducers({
    authedUser,
    transactions,
    referals,
    notifications,
    currencies,
    qrcodes,
    credits,
    subAccounts,
    wallets,
    whitelist,
    customers,
    invoices,
    products,
    shops,
    orders,
    coupons,
    subscription,
    packages,
    categories,
    issues,
    channels,
    campaigns,
    partners,
  })
);

import axios from 'axios';
import { HOST_URL } from './host';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
}
function setTransactions(transactions){
    return {
        type: SET_TRANSACTIONS,
        payload: transactions
    }
}
export function handleGetTransactions({token}, callback){
    return async (dispatch) => {
        
       return axios
    .get(`${HOST_URL}/api/user/histories`,{
        headers:{
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        if(res.status === 200){
            dispatch(setTransactions(res.data))
        }

    })
    .catch((err)=>console.error(err))
    } 
}

export async function handleGetTransaction({token, document},callback){
    
    return axios
    .get(`${HOST_URL}/api/user/history/${document}`,{
        headers:{
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        //console.log(res)
        if(res.status ===200){
            // if(res.data.success){
                callback(res.data)
            // }
        }
    })
    .catch((err)=>console.error(err))
}
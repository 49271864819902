export default{
    translation:{
        shared:{
            'comeback': 'Vous êtes de retour !',
            'welcome': 'Bienvenue sur Soleaspay !',
            'greating_username': 'Salut, {{username}}!',
            'support': 'Invitez votre ami et gagnez 500 XAF après sa première opération.',
            'back': 'Retour',
            'notifications': 'Notifications',
            'profile': 'Profile',
            'referral': 'Référence',
            'logout': 'Déconnexion',
            'setting': 'Paramètre',
            'french': 'Français',
            'english': 'Anglais',
            'downloadPdf': 'Télécharger le PDF',
            'get_started': 'Commencer ici',
            'landing': 'Acceuil',
            'copy_message': 'Copier',
            'copied_message': 'Copié!',
            'subscription':'Mon abonnement',
        },
        login:{
            'greating': 'Connectez-vous à votre compte.',
            'new': 'Ravis de vous avoir parmi nous !',
            'login': 'Connexion',
            'forgot_password': 'Mot de passe oublié ?',
            'create_new_account': 'Créer un compte.',
            'title': 'Connectez vous à partir de vos identifiants.',
            'remember_me': 'Se souvenir de moi',
            'login_success': 'Connexion effectuée avec succès!',
            'login_fail': 'Email ou mot de passe invalide!'
        },
        form:{
            'merchant':'Marchand',
            'password': 'Mot de passe',
            'email': 'Email',
            'signin': 'Connexion',
            'register': 'Inscription',
            'login': 'Connexion',
            'email_error': 'Email invalide',
            'password_error': 'Le mot de passe doit être supérieur à 4 charactères.',
            'email_required': 'Email requis',
            'password_required': 'Mot de passe requis.',
            'currency':'Devise',
            'currency_placeholder': 'Choisissez la devise par défaut',
            'new_email': 'Nouveau Email',
            'name': 'Nom',
            'name_required': 'Nom requis',
            'country': 'Pays',
            'country_required': 'Pays requis',
            'city': 'Ville',
            'city_required': 'Ville requise',
            'state': 'Région',
            'state_required': 'Région requise',
            'address': 'Adresse requise',
            'rccm_number': 'Numéro RCCM ',
            'rccm_number_required': 'Numéro RCCM requis',
            'rccm_issue_date': 'Date d\'inscription du RCCM',
            'rccm_issue_date_required': 'Date d\'inscription du RCCM requise',
            'rccm_issue_place': 'Lieu d\'immatriculation RCCM',
            'rccm_issue_place_required': 'Lieu d\'immatriculation RCCM requis',
            'company_title': 'Informations de l\'entreprise',
            'company_button': 'Mise à jour des informations de l\'entreprise',
            'username': 'Nom d\'utilisateur',
            'username_required': 'Nom d\'utilisateur requis',
            'username_error':'Nom d\'utilisateur invalide',
            'user_button': 'Modifier mon profil',
            'phone': 'Numéro de téléphone',
            'phone_error': 'Numéro de téléphone invalide',
            'phone_required': 'Numéro de téléphone requis',
            'amount': 'Montant',
            'amount_required': 'Montant requis',
            'amount_invalid': 'Le montant doit être un chiffre',
            'submit': 'Soumettre',
            'personnal': 'Personel',
            'professional': 'Professionel',
            'signup_title': 'Inscription',
            'create_account_button': 'Créer un compte',
            'contact': 'Contact',
            'contact_required': 'Contact requis',
            'mode_required': 'Type de compte requis',
            'agree_required': 'Votre accord est requis',
            'email_invalid': 'Email invalide',
            'new_password': 'Nouveau mot de passe',
            'new_password_required': 'Nouveau mot de passe requis',
            'confirm_password': 'Confirmer mot de passe',
            'confirm_password_required': 'Confirmez votre mot de passe',
            'confirm_password_invalid': 'Le mot de passe doit correspondre',
            'instance_invalid_range': 'Le nombre de QR code doit être compris entre 1 et 77',
            'reset_password_button': 'Réinitialisez le mot de passe',
            'canceled_action': 'Opération annulée',
            'shopname': 'Nom de la boutique',
            'description': 'Description',
            'mode': 'Mode',
            'instance': 'Nombres',
            'langue': 'Langue',
            'select_currency': 'Selectionnez la devise',
            'select_langue': 'Selectionnez la langue',
            'select_mode': 'Selectionnez le mode de paiement',
            'shopname_required': 'Nom de la boutique requis',
            'shopname_invalid': 'Nom de la boutique invalide',
            'description_invalid_range': 'la description doit être inferieure à 50 charactères.',
            'mode_payment_required': 'Mode de paiement requis',
            'currency_required': 'Devise requise',
            'langue_required':'Langue requise',
            'generate_qrcode_button': 'Générer le lien de paiement',
            'instance_invalid': 'Nombre de Qr code invalide',
            'instance_required': 'Nombre de Qr code requis',
            'langue_invalid': 'Langue invalide',
            'receiver_matricule_required': 'Bénéficiaire requis',
            'send_email_button': 'Recevoir un mail',
            'update_password_button': 'Changer le mot de passe',
            'home' : 'Acceuil',
            'pricing': 'Nos Tarifs',
            'pricing_body' : 'Nous appliquons le taux de commission le plus bas du marché avec des performances système élevées et sécurisées pour améliorer l\'expérience utilisateur et faciliter le développement des entreprises partenaires.',
            'pricing_rate': 'Tarif',
            'pricing_start': 'A partir de',
            'pricing_payin': 'Payin',
            'pricing_withdraw': 'Retrait',
            'first_name': 'Nom de famille',
            'last_name': 'Prénom',
            'civility': 'Civilité',
            'date_of_birth': 'Date de naissance',
            'marital_status': 'Statut matrimonial',
            'profession': 'Profession',
            'nui': 'Numero d\'identification',
            'identity_doc_type': 'Type de la pièce',
            'id_issue_date': 'Date de délivrance',
            'id_issue_place': 'Lieu de délivrance',
            'street': 'Quartier',
            'personal_information_button': 'Modifier les informations personelles',
            'title_required': 'Titre requis',
            'bank_required': 'Banque requise',
            'wallet_required': 'Compte requis',
            'contact_invalid': 'Contact invalide',
            'username_invalid': 'Nom d\'utilisateur invalide',
            'password_invalid': 'Mot de passe invalide : (Il doit contenir au moins 8 characteres avec 1 Lettre majuscule, 1 lettre minisule, 1 chiffre et 1 charactere spécial)',
            'password_confirm_invalid': 'Mot de passe inexacte',
            'password_confirm_required': 'Confirmation du mot de passe requis',
            'otp_required': 'Code requis',
            'date_of_birth_required': 'Date de naissance requise',
            'street_required': 'Quartier requis',
            'profession_required': 'Profession requise',
            'nui_required': 'Numéro d\'identification requis',
            'id_issue_date_required': 'Date de délivrance requise',
            'id_issue_place_required': 'Lieu de délivrance requis',
            'ip_required':'Adresse IP requise',
            'ip_invalid' : 'Adresse IP invalide',
            'receiver_name':'Nom',
            'receiver_username':'Nom d\'utilisateur',
            'receiver_matricule':'Matricule',
            'receiver_info_title':'Informations du bénéficiaire',
            'receiver_search':'Bénéficiaire',
            'receiver_search_desc':'Saisir une information du bénéficiaire pour la recherche',
            'receiver_search_placeholder':'Rechercher le bénéficiaire',
            'receiver_select':'Choisissez le bénéficiaire',
            'receiver_not_found':'Aucun utilisateur trouvé',
            'receiver_search_no_result':'Aucun resultat pour cette recherche !',
            'name_required': 'Nom requis',
            'name_invalid': 'Nom invalide',
            'email_required': 'Email requis',
            'email_invalid': 'Email invalide',
            'country_required': 'Pays requis',
            'country_invalid': 'Pays invalide',
            'products_required': 'Produits requis',
            'products_invalid': 'Produits invalide',
            'type_required': 'Type requis',
            'type_invalid': 'Type invalide',
            'language_required': 'Langue requise',
            'language_invalid': 'Langue invalide',
            'currency_required': 'Devise requis',
            'currency_invalid': 'Devise invalide',
            'note_required': 'Note requise',
            'note_invalid': 'Note invalide',
            'discount_required': 'Remise requise',
            'discount_invalid': 'Remise invalide',
            'tax_required': 'Taxe requise',
            'tax_invalid': 'Taxe invalide',
            'customer_required': 'Client requise',
            'customer_invalid': 'Client invalide',
            'description_required':'Description requise',
            'price_required':'Prix requis',
            'category_required': 'Categorie requise',
            'category_invalid': 'Categorie invalide',
            'reference_required': 'Reférence requise',
            'Photo_required': 'Image requise',
            'value_required':'Valeur requise',
            'message_required':'Message requis',
            'channel_required':'Canal requis',
            'date_required':'Date requise',
            'template_required':'Modèle requis',
            'about_required':'À propos requis',
            'cycle_required':'Cycle requis',
            'code_required':'Code requis',
            'business_phone': 'Muméro Professionel',
            'business_email': 'Adresse email professionnelle',
            'registration': 'Numéro d\'immatriculation'
        },
        admin:{
            'dashboard': 'Tableau de bord',
            'search': 'Rechercher',
            'balance': 'Solde',
            'rewards': 'Récompense',
            'referrals': 'Mes Référés',
            'requests': 'Requêtes',
            'trans_list': 'Historique des transactions',
            'services': 'Services SoleasPay',
            'notifications': 'Notifications',
            'user_profile': 'Mon Profil',
            'trans_amount': 'Montant des transactions',
            'trans_total': 'Transactions totales',
            'trans_recent': 'Transactions Récentes',
            'referral_list': 'Liste des référés',
            'referral_recent': 'Référés récents',
            'see_all': 'Voir tout',
            'referral_empty': 'Aucun référé',
            'trans_empty': 'Aucune Transaction',
            'my_profile': 'Mon profil',
            'setting': 'Paramètre',
            'logout': 'Déconnexion',
            'documentation': 'Documentation',
            'back': 'Retour',
            'month': 'Mois',
            'day':'Jour',
            'trans_history':'Historique',
            'payment_api': 'Intégration API de Paiement',
            'operations': 'Opérations',
            'score' : 'Score',
            'overview':'Aperçu',
            'user_customers': 'Mes Clients',
            'user_products': 'Mes Produits',
            'issues': 'Mes Reclammations',
            'user_product_new': 'Mes Produits',
            'shops':'e-Business',
            'user_company':'Mon entreprise',
            'coupon':'Coupon de réduction',
            'orders':'Commandes',
            'user_customer_new': 'Nouveau client',
            'payments': 'Paiements',
            'channel': 'Mes canaux de diffusion',
            'marketing': 'Marketing booster',
            'campaign': 'Mes campagnes',
            'partners':'Mes partenaires',
            'import':'Importer la liste',
        },
        transaction:{
            'reference': 'Référence',
            'operation': 'Opération',
            'status': 'Statut',
            'date': 'Date',
            'transaction_amount': 'Montant de la Transaction (SPC)',
            'receiver': 'Récepteur',
            'amount': 'Montant',
            'verify': 'Vérifié',
            'transaction_detail': 'Détail de la Transaction',
            'doc_empty': 'Aucun Document',
            'see_detail': 'Voir les Détails',
            'sender': 'Initiateur',
            'description': 'Description',
            'wallet':'Compte',
            'in_service': 'De',
            'in_wallet': 'Du compte',
            'in_amount': 'Montant envoyer',
            'out_service': 'A',
            'out_wallet': 'Au compte',
            'out_amount': 'Montant reçu',
            'payer': 'Payeur',
            'service': 'Service',
            'details': 'Details sur la transaction',
            'new_issue': 'Signaler un problème',
        },
        profile:{
            'switch_account_button': 'Passer à un compte professionel',
            '2fa_button_off': 'Activer l\'authentification à double facteurs',
            '2fa_button_on': 'Passer à l\'authentication par défaut',
            '2fa_title': 'Authentication',
            '2fa_message_default': 'Vous êtes en authentification par défaut',
            '2fa_message_activated': 'Authentification à double facteurs activée',
            'currency_title': 'Devise Par Défaut',
            'currency_button': 'Changer de devise',
            'change_email_title': 'Changer d\'email',
            'change_email_button': 'Changer d\'email',
            'change_avatar': 'Changer d\'avatar',
            'delete_account_title': 'Supprimer mon compte',
            'delete_account_warning_message': 'Vous allez supprimer votre compte, vous perdrez toutes vos données et votre argent. Êtes vous sûr de vouloir le faire ?',
            'detete_account_warning_confirm': 'Je veux vraiment supprimer mon compte',
            'user_title': 'Informations d\'utilisateur',
            'update_password_title': 'Modifier le mot de passe',
            'update_password_button': 'Modifier le mot de passe',
            'change_currency_title': 'Devise par défaut',
            'matricule': 'Matricule',
            'level': 'Niveau',
            'referal_link': 'Lien d\'invitation',
            'goto': 'Allez à',
            'company_title':'Informations de l\'entreprise',
            'account_title': 'Mon profil',
            'delete_account_warning_confirm': 'Oui, je suis sûr !',
            'change_password_title': 'Changer mon mot de passe',
            'extension_error': 'Choisir une image prise en charge (PNG/JPEG)',
            'personal_title': 'Informations personnelles',
            'api_title': 'Integration de l\'API',
            'api_informations_title': 'Obtenir les informations pour API',
            '2fa_mode_title': '2FA mode',
            'refresh_apikey':'Voulez-vous vraiment actualiser votre API_KEY ?'
        },
        operation: {
            'sender_wallet': 'Compte de l\'expéditeur',
            'receiver_wallet': 'Compte du récepteur',
            'operation_title': 'Information {{operation}}',
            'title': 'Transactions',
            'service': 'Nos services',
            'service_title': 'Quelle opération voulez-vous réaliser ?',
            'transfert_money': 'Transfert d\'argent',
            'transfert_money_description': 'Envoyez de l\'argent à n\'importe qui sur SoleasPay avec des frais assez bas (~1%).',
            'withdraw_money': 'Retrait d\'argent',
            'withdraw_money_description': 'Retirez de l\'argent instantanément de votre compte SoleasPay vers votre portefeuille personnel sans frais.',
            'deposit_money': 'Recharger mon compte',
            'deposit_money_description': 'Créditez gratuitement votre compte SoleasPay à partir des différents moyens de paiement disponibles.',
            'withdraw_rewards': 'Retirer mes récompenses',
            'withdraw_rewards_description': 'Retirez vos récompenses vers votre solde SoleasPay.',
            'withdraw_fund_title': 'Retirer de l\'argent vers un compte mobile ou digital',
            'swap_money': 'E-Change',
            'swap_money_description': 'Transférez de l\'argent de l\'un de vos portefeuilles personnels à un autre.',
            'payment_api_integration': 'Intégration de l\'API de paiement',
            'payment_api_integration_description': 'Intégrez notre API dans votre projet et recevez vos paiements directement dans votre compte SoleasPay.',
            'qr_code_payment': 'Lien de paiement',
            'qr_code_payment_description': 'Étendez votre business en recevant des paiements directement via un lien court ou un QR code généré par notre système et maximisez vos ventes.',
            'transfert_funds_title': 'Transfert d\'argent',
            'withdraw_information': 'Information de retrait',
            'receiver_matricule': 'Matricule du bénéficiaire',
            'deposit_fund': 'Recharger mon compte SoleasPay',
            'amount_in_xpi': 'Montant en SPC',
            'qrcode_information': 'Informations sur le lien de paiement',
            'qrcode_generator_title': 'Générateur de liens de paiement',
            'mode_billing': 'Facture liée à un produit',
            'mode_tipping': 'Collecte/Encaissement',
            'canceled_action': 'Opération annulée',
            'withdraw_rewards_title': 'Information retrait de récompense',
            'out_amount': 'Montant à recevoir',
            'withdraw_cash': 'Retirer du cash',
            'withdraw_cash_description': 'Effectuez un retrait de votre compte SoleasPay directement en espèces, via les agences Express Union ou à travers votre compte bancaire.',
            'wallet': 'Compte à débiter',
            'description': 'Motif du transfert',
            'transfert_information': 'Informations sur le transfert',
            'withdraw_confirmation_title': 'Confirmer la transaction avec le code reçu dans votre boîte mail.',
            'confirmation_code': 'Code OTP',
            'withdraw_submit_code': "Confirmer le retrait",
            "sopay_credits": "Microcrédit via SoleasPay",
            "sopay_credits_description": "Souscrivez à un microcrédit SoleasPay en fonction de votre volume d'activité. Notre solution est flexible, vous permettant de paramétrer vous-même votre mode de remboursement.",
            "sopay_payment": "Paiement SoleasPay",
            "sopay_payment_description": "En développement",
            'withdraw':'Retrait',
            'deposit': 'Recharger',
            'confirm_action':'Confirmer',
            'cancel_action':'Annuler',
            'mode_tiping':'Collecte de fond',
            'select_country':'Services disponibles pour :',
          },          
          integration: {
            'header': 'Intégration de l\'API de paiement',
            'api_access_title': 'ACCÈS API',
            'activate_key_title': 'ACTIVER LA CLÉ API',
            'sopay_button_title': 'INTÉGRATION DU BOUTON SOPAY',
            'api_documentation_title': 'DOCUMENTATION DE L\'API',
            'refresh_api': 'Actualiser la clé API',
            'withdraw_access': 'ACCÈS AU RETRAIT',
            'api_withdraw_msg_warning': 'Votre clé API n\'est pas activée pour les opérations de retrait. Veuillez contacter le support pour l\'activer.',
            'api_withdraw_msg_info': 'Votre clé API est activée pour les retraits. Profitez-en :)',
            'deposit_access': 'ACCÈS À LA RÉCEPTION DES PAIEMENTS',
            'api_deposit_msg_warning': 'Votre clé API n\'est pas activée pour la collecte des paiements. Veuillez contacter le support pour l\'activer.',
            'api_deposit_msg_info': 'Votre clé API est activée pour la collecte des paiements. Profitez-en :)',
            'sopay_button_description': 'Le bouton Sopay est prêt à être intégré, commencez dès maintenant.',
            'api_documentation_description': 'La documentation de l\'API est facile à comprendre.',
            'api_documentation_link': 'Lire la documentation'
          },
          notification: {
            'title': 'Titre',
            'status': 'Statut',
            'date': 'Date',
            'read': 'Lu',
            'unread': 'Non lu',
            'notifications': 'Notifications',
            'content': 'Contenu',
            'notification_empty': 'Aucune notification',
            'notification_detail': 'Détails de la notification'
          },          
          auth: {
            'signup_as': 'S\'inscrire en tant que',
            'privacy_policy': 'Politique de confidentialité',
            'agree_label': 'J\'accepte les termes suivants de la ',
            'forgot_password_title': 'Mot de passe oublié',
            'backup_password_email_button': 'Modifier le mot de passe',
            'reset_password_title': 'Réinitialisez votre mot de passe',
            'go_to_login': 'Retourner à la connexion',
            'activated_account_success':  'Votre compte a été activé avec succès',
            'activation_mail_send': 'Mail d\'activation envoyé dans votre boîte mail',
            'activation_mail_info': 'Fournissez votre e-mail pour recevoir le mail d\'activation',
            '2fa_login_title': 'Entrez le code 2FA envoyé par email pour confirmer.',
            '2fa_otp': 'Code 2FA',
            'submit_2fa': 'Envoyer le code OTP',
            'forgot_password_mail_send': 'Mail de réinitialisation envoyé dans votre boîte mail'
          },
          withdrawFunds: {
            'confirm_action': 'Voulez-vous retirer {{amount}} {{currency}} vers le compte {{wallet}} ?',
            'cash_title': 'Retirer de l\'argent vers un compte bancaire'
          },
          withdrawRewards: {
            'confirm_action': 'Voulez-vous retirer {{amount}} SPC ?'
          },
          depositFunds: {
            'confirm_action': 'Voulez-vous créditer {{amount}} {{currency}} à partir du compte {{wallet}} ?'
          },
          transfertFunds: {
            'confirm_action': 'Voulez-vous transférer {{amount}} {{currency}} vers le compte {{wallet}} ?'
          },
          swap: {
            'confirm_action': 'Voulez-vous échanger {{amount}} de votre compte {{currency}} ?',
            'title': 'Transférer de l\'argent d\'un portefeuille à un autre'
          },          
          qrcode: {
            list: {
              title: 'Liste des liens',
              empty: 'Vide',
              see_detail: 'Voir les transactions'
            },
            property: {
              reference: 'Référence',
              type: 'Type',
              status: 'Statut',
              link: 'Lien',
              expiredAt: 'Date d\'expiration',
              amount: 'Montant'
            },
            action: {
              new: 'Créer un lien de paiement',
            },
            details: {
              title: 'Détails des transactions'
            }
          },          
        landing:{
            service:{
                'title': 'Nos Services',
                'subtitle': 'Nous proposons des solutions de paiement numerique dans multiples domaines',
                'payment': 'Effectuer et recevoir des paiements',
                'exchange': 'E-Change',
                'transfert':'Transfert Soleaspay',
                'rewards': 'Gagner des récompenses',
                'internationalPayments': 'Paiements internationaux',
                'liveHistory':'Historique en direct',
                'Support':'Support H24/7',
                'integrationApi':'Rest APIs',
                'paymentDescription': 'Que vous soyez une boutique en ligne, un marchand en ligne ou un simple consommateur, vous pouvez envoyer et recevoir des paiements instantanément sur Soleaspay.',
                'exchangeDescription': 'Transférez votre argent d\'un de vos comptes personnels à un autre rapidement avec les taux de change les plus bas du marché.',
                'transfertDescription':'Transférez gratuitement de l\'argent de votre compte vers un autre compte Soleaspay avec des paramètres de sécurité définis par vous (direct ou soumis à condition).',
                'rewardsDescription': 'En effectuant des transactions sur Soleaspay, vous pouvez gagner des récompenses pour toutes les opérations nécessitant des frais. Vous gagnez également des récompenses sur toutes les transactions des comptes Soleaspay que vous avez parrainer.',
                'internationalPaymentsDescription': 'Soleaspay vous permet d\'accepter les paiements internationaux par les canaux légaux (Visa, Paypal, etc.) pour faciliter la vente de vos services en ligne et promouvoir la notoriété de votre produit à l\'international.',
                'liveHistoryDescription':'Bénéficiez d\'un historique en temps réel de toutes les transactions effectuées depuis ou vers votre compte.',
                'SupportDescription':'Une équipe technique est disponible en temps réel pour vous accompagner pas à pas 24h/24 et 7j/7.',
                'integrationApiDescription':'Pour des solutions personnalisées, intégrez facilement et rapidement (une seule fois) tous les moyens de paiement supportés par Soleaspay dans votre projet.',
                'qr': 'Soleaspay Qr Code',
                'qrDescription': 'Générez gratuitement un code Qr pour vendre ou recevoir un paiement instantanément dans votre compte Soleaspay et gérez chacun d\'eux',
                'crypto': 'Acheter et Vendre la cryptomonnaie',
                'cryptoDescription': 'Achetez et vendez instantanément de la crypto-monnaie à partir de votre compte Soleaspay ou en utilisant votre portefeuille mobile personnel au meilleur taux du marché',
                'freeOfCharge' : 'Zéro frais',
                'freeOfChargeDescription': 'La création de compte, l\'intégration de l\'Api, le dépôt et le retrait de fonds sont gratuits sur Soleaspay. Même maintenir votre compte est gratuit'
            },
            calculation: {
                'title': 'Conversion Directe',
                'description': 'Obtenez la conversion en direct des devises prises en charge (USD, EUR, BTC, DOGE, etc.)',
                'currency_calculator': 'Taux actuel de SoleasPay',
                'convert_amount': 'Montant converti',
              },
              stock: {
                'title': 'Nos réserves',
                'description': 'Réserves actuelles de SoleasPay pour des transactions instantanées'
              },
              downloadApp: {
                title: 'Application mobile disponible bientôt',
                description: 'Notre application mobile offre la possibilité de trader directement via les monnaies locales et bien plus encore',
              },
              partner: {
                title: 'Nos partenaires',
                subtitle: 'Soyez en sécurité lorsque vous effectuez des transactions sur SoleasPay'
              },
              slider: {
                item1: 'Devenez partenaire et développons votre entreprise ensemble',
                item2: 'Recevez de l\'argent de vos clients partout dans le monde',
                item3: 'Recevez et envoyez de l\'argent et de la crypto-monnaie de n\'importe où, sans limite',
                item4: 'Intégrez un paiement numérique dans votre solution en ligne'
              },              
            faq:{
                head:'Questions fréquemment posées',
                title:'SoleasPay en detail',
                'about_title':'Qu\'est ce que Soleaspay ?',
                'about_content':'<strong>SoleasPay</strong> est une passerelle de paiement qui aggrège pret de 50 moyens de paiements au cameroun et a l\'étranger afin de permetre aux marchands de recevoir les paiements de leurs clients partout dans le monde.',
                'use_title': 'A quoi ça sert ?',
                'use_content': 'Soleaspay est utilisé pour : <ul><li>La réception des paiements via des outils comme le QR code ou des liens de paiement;</li><li>L\'achat et la vente des devises et cryptomonnaies;</li><li>La monétisation des sites web, e-commerces et/ou des applications mobile via l\intégration du sopay button ou de l\'API de paiement Soleaspay;</li><li>Le paiement des services utile (airtime, camtel, eneo, camwater et canal plus);</li><li>Le transfert d\'argent entre utilisateurs à moindre coût</li>',
                'who_can_use_title':'Qui peut utiliser Soleaspay ?',
                'who_can_use_content':'Soleaspay est conçu pour être pris en main par des professionnel du développement mais aussi et surtout par tout utilisateur n\'ayant aucune notion en programmation informatique. Il s\'emploi aussi bien pour les commerçants traditionnel que pour les e-commerçants',
                'account_type_title':'Quels sont les types de Compte sur Soleaspay ?',
                'account_type_content':'Il existe trois (03) types de comptes sur Soleaspay : <ul><li><strong>Compte personnel</strong>: Il appartient à tout utilisateur Soleaspay. il permet d\'éffectuer ses paiements de biens et services, de transférer de l\'argent à un autre utilisateur Soleaspay;</li><li><strong>Compte Marchant</strong>: Idéal pour les marchands et e-marchands il offre en plus des attributions du compte personnel, la possibilité de générer et distribuer des qr codes et des liens de paiement afin de recevoir l\'argent de leurs clients</li><li><strong>Compte Professionnel</strong>: Indispensable pour les entreprises, il permet d\'intégrer et consommer les APIs de paiement et celle de retrait (PayIn et PayOut)</li></ul>',
                'account_create_title':'Comment créer un Compte Soleaspay ?',
                'account_create_content':'Il suffit de remplir le formulaire d\'inscription à l\'adresse : <a href="/auth/register"><em>S\'inscrire ici</em></a>',
                'account_activate_title':'Comment activer mon compte Soleaspay ?',
                'account_activate_content':'Apres avoir rempli le formulaire de création de compte, il faut se rendre dans la boite mail de l\'adresse email fourni à l\'inscription et cliquer sur le bouton <em>activer mon compte</em>.',
                'account_re_activate_title':'J\'ai pas reçu de Mail d\'activation',
                'account_re_activate_content':'Si le mail d\'activation ne se retrouve pas même dans votre dossier spam, rendez vous l\'adresse : <a href="/auth/email-activation">Me Renvoyer le mail d\'activation</a>',
                'account_kyc_title':'C\'est quoi le kyc sur SoleasPay ?',
                'account_kyc_content':'Sur Soleaspay nous metont un point d\'honneur sur l\'identification de nos utilisateur ainsi le kyc(Know Your Custommer) est l\'ensemble des techniques que nous utilisons afin de garder à jours les informations que nous avons sur nos utilisateurs.',
                'transactions_title':'Quelles sont les différente transactions disponible sur Soleaspay ?',
                'transactions_content':'Les différentes transactions pouvant être éffectuer sur Soleaspay sont les suivantes : <ul><li>La réception de paiement</li><li>l\'émission des paiements</li><li>L\'achat de crédit de communication</li><li>L\'achat et la vente des devises</li><li>Le transfert d\'argent entre uilisateurs</li></ul>',
                'transactions_fees_title': 'Que me coute les services de Soleaspay ?',
                'transactions_fees_content': 'Chez SoleasPay, nous employons une politique de facturation à la consommation cela dit, vous paierez uniquement lors des transactions pour lesquels l\'argent sort du sopay pour un autre compte. <br> l\'ensemble de nos tarifs est disponible à l\'adresse <a href="/pricing"> Nos Tarifs</a>',
                'account_limitation_title': 'Y a il des limitation à l\'utilisation de mon compte Soleaspay ?',
                'account_limitation_content': 'L\'utilisation d\'un compte Soleaspay est limitée si et seulement si son proprietaire n\'a pas renseigner son Kyc. <br> Veuillez contacter le support pour plus d\'information liée à votre compte',
                'account_lifetime_title':'Combien de temps puis je utiliser mon compte Soleaspay ?',
                'account_lifetime_content':'Un compte Soleaspay une fois activé est disponible indéfiniment. Néamoins vous pourrez à tout moment le supprimer dans votre dashboard.',
                'account_additional_fees_title':'Y a il des frais supplementaire à l\'utilisation de Soleaspay ?',
                'account_additional_fees_content':'Non, il n\'y a pas de frais supplementaire pour l\'exploitation d\'un compte soleaspay',
                'api_use_title':'Comment intégrer Soleaspay à mon projet ?',
                'api_use_content':'Vous devez utiliser l\'une des deux integrations disponible sur soleaspay à savoir : <ul><li><strong>Le Sopay Button</strong> : c\'est un widget complet intégrant tous nos moyens de paiement via un script JS</li><li><strong>L\'API Soleaspay</strong>: Pour une utilisation personnalisée, une intégration via notre api est ideal.</li></ul>',
                'api_type_title':'Quels est le type d\'API de Soleaspay ?',
                'api_type_content':'L\'API de Soleaspay est une API FULL REST',
                'api_doc_title':'Comment intégrer l\'API Soleaspay ?',
                'api_doc_content':'Il suffit de suivre la documentation de l\'API disponible à l\'adresse : <a href="https://doc.mysoleas.com/sopay-api">Soleaspay Doc</a>'
            }, 
            'language':'LANGUE',
            'language_fr': 'Français',
            'language_en': 'Anglais'
        },
        menu: {
            accounts: 'Mes portefeuilles',
            'external_accounts': 'Comptes externes',
            'new_external_account': 'Nouveau compte externe',
            'user_setting': 'Configuration',
            'sub_accounts': 'Sous-comptes',
            'new_sub_account': 'Nouveau sous-compte',
            'credit_list': 'Mes microcrédits',
            'new_credit': 'Nouveau microcrédit',
            'qrcode_list': 'Mes liens de paiement',
            'whitelist_ip': 'Liste blanche IP',
            'whitelist_ip_add': 'Ajouter une IP',
            'card_list' : 'Mes cartes visa virtuelles',
            'issues' : 'Mes Reclammations',
            'subscription':'Mon abonnement',
            'packages': 'Packs disponible'
          },
          balance: {
            title: 'Mes comptes',
            name: 'Devise',
            wallet: 'Compte',
            amount: 'Solde',
            amount_converted: 'Solde converti',
            sell: 'Vendre'
          },
          trade: {
            'sell_currency': 'Vendre une devise',
            'sell_for': 'Changer pour',
            'choose_currency': 'Sélectionnez une devise'
          },
          wallet: {
            'list_title': 'Comptes externes',
            'action_new': 'Nouveau compte',
            'title': 'Titre',
            'account': 'Compte',
            'bank': 'Banque',
            'service': 'Service',
            'setting': 'Éditer',
            'new_description': 'Ajouter un compte',
            'wallet': 'Compte',
            'select_service': 'Sélectionnez un service',
            'byDefault': 'Par défaut',
            'byDefault_true': 'Oui',
            'byDefault_false': 'Non',
            'empty': 'Aucun compte trouvé',
            'informations': "Informations sur le compte",
            'status': "Statut",
            'confirm_action':'Voulez-vous vraiment ajouter ce compte externe ?'
          },          
          setting: {
            'create_sub_account': 'Créer un sous-compte',
            'sub_account_action_new': 'Nouveau sous-compte',
            'sub_account_list_title': 'Mes sous-comptes',
            'sub_account_username': 'Nom',
            'sub_account_email': 'Email',
            'sub_account_contact': 'Contact',
            'sub_account_balance': 'Solde',
            'sub_account_status': 'Statut',
            'sub_account_list_empty': 'Aucun sous-compte',
            'sub_account_details': 'Détails',
            'sub_account_edit': 'Éditer',
            'sub_accounts_detail': 'Informations sur le compte',
            'sub_account_matricule': 'Matricule',
            'sub_account_last_operation': 'Dernière opération à',
            'sub_account_withdraw': 'Retrait autorisé',
            'sub_account_transfert': 'Transfert autorisé',
            'sub_account_credit': 'Crédit autorisé',
            'sub_account_description': 'Créer un sous-compte',
            'sub_account_title': 'Informations sur le sous-compte',
            'account_title': 'Paramètres du compte',
            'whitelist_ip': 'Liste blanche IP',
            'whitelist_title': 'Mes adresses IP',
            'whitelist_action_new': 'Ajouter une IP',
            'whitelist_item_no': 'Item',
            'whitelist_status': 'Statut',
            'whitelist_enable': "Activer l'IP",
            'whitelist_disable': "Désactiver l'IP",
            'whitelist_delete': "Supprimer l'IP",
            'whitelist_empty': "Liste blanche vide",
            'kyc_process': "Vérifier mon compte",
            'account_setting': "Paramètres du compte",
            'account_setting_list_title': "Mes paramètres",
            'account_setting_label': "Paramètre",
            'account_setting_credit_allow': "Demande de crédit",
            'account_setting_withdraw_allow': "Retrait du compte",
            'account_setting_transfert_allow': "Transfert de solde",
            'account_setting_services': "Mes services",
            'account_setting_edit_services': "Sélectionner",
            'account_services': "Mes services",
            'account_service_name': "Méthode de paiement",
            'account_service_country': "Pays",
            'confirm_new_account' : "Voulez-vous vraiment créer ce sous compte ?",
            'account_setting_customer_paid_fees' : 'Frais payés par le client',
            'account_setting_webhook' : 'Lien de notification (Whebhook)',
            'account_setting_hash' : 'Hash de securité notification',
            'account_setting_send_webhook' : 'Envoyer',
            'company':'Mon entreprise',
            'confirm_switch_up_action' : 'Migrer le compte vers un compte professionel ?',
            'switch_up': 'Passer au compte Pro',
            'account_setting_campaign_email' : 'Email de la Campagne marketing',
            'account_setting_campaign_label': 'Nom de la prochaine Campagne',
            'kyc_complete' : 'Entreprise vérifiée'
          },          
          credit: {
            'list_title': 'Liste des microcrédits',
            'action_new': 'Nouveau crédit',
            'property_reference': 'Référence',
            'property_link': 'Lien',
            'property_expiredat': "Date d'expiration",
            'property_type': 'Type',
            'property_status': 'Statut',
            'list_empty': "Aucun crédit pour l'instant",
            'new_description': 'Souscrire à un microcrédit',
            'new_title': 'Nouveau crédit',
            'max_amount': 'Montant maximal à demander',
            'amount_in': 'Montant en',
            'return_times': 'Nombre de tranches',
            'select_times': 'Sélectionnez une tranche',
            'one_time': 'Une tranche',
            'two_times': 'Deux tranches',
            'three_times': 'Trois tranches',
            'four_times': 'Quatre tranches',
            'return_cycle': 'Cycle de remboursement',
            'select_cycle': 'Sélectionnez un cycle',
            'cycle_day': 'Journalier',
            'cycle_week': 'Hebdomadaire',
            'cycle_month': 'Mensuel',
            'cycle_two_months': 'Bimestriel',
            'cycle_quarter': 'Trimestriel',
            'cycle_semester': 'Semestriel',
            'cycle_year': 'Annuel',
            'description': "Motif du crédit",
            'step_amount_in': "Montant de la tranche en",
            'nextdue': "Date de la prochaine tranche",
            'return_amount_in': "Montant à rembourser en",
            'deadline': "Date limite du remboursement",
            'confirm_action': "Etes-vous d\'accords avec les clauses de ce prets ?"
          },
          whitelist: {
            'new': "Nouvelle IP",
            'ip': "Adresse IP à ajouter",
            'confirm_action':'Voulez-vous vraiment ajouter cette adresse IP ?'
          },  
          verify:{
            'verify_title':'Vérification d\'identité',
            'id_photo_label':'Photo de la pièce d\'identité',
            'user_photo_label':'Photo de vous tenant la piece d\'identité',
            'document_type_label':'Type de pièce d\'identité',
            'document_number_label':'Numéro de la pièce d\'identité',
            'select_option':'Choississez le type de pièce d\'identité',
            'IDCard':'CNI',
            'passport':'Passeport',
            'driving_license':'Permis de conduire',
            'issue_date_label':'Date de délivrance',
            'expiry_date_label':'Date d\'expiration',
            'submit_button':'Soumettre',
            'localisation_photo_label':'Photo du plan de localisation de votre domicile',
            'other_photo_label':' photo d\'une autre pièce justificative',
            'register':'Photo du registre de commerce',
            'office_photo_label':'Photo du bureau|local',
            'localisation_photo_label':'Plan de localisation',
          },    
          card:{
            'list_title':'Liste de mes cartes visa virtuelles',
            'action_new': 'Créer une carte visa',
            'list_empty': 'Aucune carte virtuelle',
            'type': 'Type',
            'number': 'Numero',
            'status': 'Status',
            'balance': 'Solde',
            'expiredAt': 'Date d\'expiration',
            'new_description': 'Ajouter une carte virtuelle',
            'firstName': 'Nom',
            'lastName': 'Date d\'expiration',
            'amount': 'Montant',
            'currency': 'Devise',
            'select_currency': 'choisissez une devise',
            'address': 'Adresse',
            'billingCity': 'Ville',
            'billingCountry': 'Pays',
            'postal_code': 'Code postal',
            'confirm_action': 'Voulez-vous vraiment ajouter ma carte visa virtuelle ?',
            'usd': 'USD',
            'eur': 'EUR',
          },
          dashboard:{
            'visa_title':'Carte visa virtuelle',
            'visa_desc':'Obtenez une carte visa virtuelle pour des paiements en ligne sécurisés.',
            'service_title':'Achat de services',
            'service_desc':'Achetez facilement les services dont vous avez besoin grâce à notre plateforme.',
            'swap_title':'Swap',
            'swap_desc':'Transférez de l\'argent de l\'un de vos portefeuilles personnels à un autre.',
            'link_title':'Liens de paiement',
            'link_desc':'Facilitez vos transactions grâce à notre générateur de liens de paiement.',
            'whatsapp_bot_title':'Whatsapp bot payment',
            'whatsapp_bot_desc':'Effectuez facilement des paiements via WhatsApp avec notre bot de paiement.',
            'API_title':'API de paiement et plugins',
            'API_desc':'Intégrez notre API et nos plugins pour recevoir vos paiements directement dans votre compte SoleasPay.',
            'transfert_title':'Transfert argent',
            'transfert_desc':'Envoyez de l\'argent à n\'importe qui sur SoleasPay avec des frais assez bas (~1%).',
            'credit_title':'Micro Crédit',
            'credit_desc':'Souscrivez à un micro crédit SoleasPay en fonction de votre volume d\'activité.',
            'withdraw_cash_title':'Retrait de cash',
            'withdraw_cash_desc':'Retirez de l\'argent de votre compte SoleasPay en espèces via Express Union ou votre compte bancaire.',
            'localisation_photo_label':'Plan de localisation de votre domicile',
            'other_photo_label': 'Autre document',
            'invoice_title' : 'E-Facturier',
            'invoice_desc' : 'Créer et envoyez des factures à vos clients simplement et gardez le contact avec eux.',
            'shop' : 'E-Business',
            'shop_desc' : 'Créez et gérez facilement vos boutiques, clients et produits.',
            'marketing_booster' : 'Marketing booster',
            'marketing_booster_desc' : 'Envoyez des e-mail marketing pour promouvoir vos produits, informer vos clients, fidéliser votre clientèle.',
            'payment_title' : 'Paiement',
            'payment_desc' : 'Collectez et Envoyez des paiement en toute simplicité'
          },
          dashboard_mobile:{
            'visa_title':'Carte visa',
            'service_title':'Achat Services',
            'swap_title':'Swap',
            'link_title':'Liens de paiement',
            'API_title':'API et plugins',
            'transfer_title':'Transfert argent',
            'credit_title':'Micro Crédit',
            'withdraw_cash_title':'Retrait de cash',
            'other_photo_label': 'Autre document',
            'invoice_title' : 'E-Facturier',
            'shop' : 'E-Business',
            'marketing_booster' : 'Marketing booster',
            'payment_title' : 'Paiement',
          },
          issue:{
            'reference': 'Reférence',
            'amount': 'Montant',
            'description': 'Description du Problème',
            'statut': 'Status de la reclammation',
            'list_title': 'Mes Reclammations',
            'currency': 'Devise de la transaction',
            'new': 'Nouvelle reclammation',
            'imageissue': 'Joindre une image à la requête',
          },
          product:{
            'list_title': 'Mes produits',
            'description':'Brève description du produit',
            'price': 'Prix',
            'action_new': 'Nouveau produit',
            'action_edit': 'Modifier le produit',
            'name': 'Nom du produit',
            'new_description': 'Ajouter un produit',
            'details': 'Informations',
            'edit': 'Modifier',
            'empty':'Aucun produit',
            'detail': 'Informations',
            'delete': 'Supprimer',
            'informations': 'Informations du produit',
            'stasus': 'statut',
            'category': 'Catégorie du produit',
            'select_category': 'Selectionnez une catégorie',
            'image1': 'Photo du produit',
            'image2': 'Autre photo du produit',
            'confirm_action': 'Voulez-vous vraiment ajouter ce produit ?',
            'update_product_description':'Modifier les informations',
            'update_success_message':'Informations modifiées !',
            'update_error_message':'Echec de la modification !',
            'confirm_update':'Voulez-vous vraiment modifier ces informations ?',
            'discount':'Pourcentage de réduction',
            'status':'Statut',
            'available':'Disponible',
            'unavailable':'Indisponible',
            'enable':'Activer',
            'disable':'Désactiver',
          },
          customer:{
            'name': 'Nom',
            'contact': 'Contact',
            'address': 'Adresse',
            'list_title': 'Mes Clients',
            'action_new': 'Nouveau Client',
            'action_edit': 'Mettre à jours ce client',
            'edit': 'Modifier',
            'detail': 'Informations',
            'delete': 'Supprimer',
            'informations': 'Informations sur le client',
            'lead' : 'Contact chez le client',
            'email': 'Email du client',
            'country': 'Pays d\'exercice',
            'city': 'Ville',
            'empty':'Aucun client',
            'new_description':'Ajouter un client',
            'confirm_action': 'Voulez-vous vraiment ajouter ce client ?',
            'update_customer_description':'Modifier les informations',
            'import_csv':'Importer liste',
          },
          invoice:{
            'list_title': 'Mes factures',
            'reference': 'Reférence',
            'name' : 'Nom du client',
            'email' : 'Email du client',
            'amount': 'Montant',
            'currency': 'Devise',
            'status': 'Statut',
            'detail': 'Détails',
            'delete': 'Supprimer',
            'action_new': 'Créer une facture',
            'new_description': 'Nouvelle facture',
            'new': 'Nouvelle facture',
            'customer': 'Client',
            'select_customer': 'Selectionnez un client',
            'type': 'Type de facture',
            'select_type': 'Selectionnez le type de facture',
            'language': 'Langue',
            'select_language' : 'Selectionnez la langue',
            'currency': 'Devise',
            'select_currency': 'Selectionnez la devise',
            'discount': 'Remise',
            'taxable': 'Inclure les taxes',
            'tax': 'Pourcentage de la taxe',
            'note': 'Message pied de page',
            'empty':'Aucune facture',
            'forExistingCustomer':'Un client répertorié',
            'forNewCustomer':'Un client non répertorié',
            'select_products':'Selectionnez les produits',
            'quantity':'Définissez la quantité des produits selectionnés',
            'empty_products_quantity':'Aucun produit sélectionné',
            'type_global':'Global',
            'type_proforma':'Proforma',
            'type_deposit':'Dépot',
            'language_fr': 'Français',
            'language_en': 'Anglais',
            'xaf': 'XAF',
            'usd': 'USD',
            'eur': 'EUR',
            'confirm_action': 'Voulez-vous vraiment envoyer cette facture à votre client ?',
            'preview':'Aperçu facture',
            'for':'Facture pour : ',
            'date':'Date',
            'informations':'Détails de la facture',
            'add_action':'La facture à été envoyé au client et enregistrée',
            'error':'Erreur lors de la creaction de la facture',
            'cancel':'Opération annulée',
          },
          shops:{
            'e-shops_title':'Mon Business',
            'shops_title':'Mes boutiques',
            'new_shop':'Nouvelle boutique',
            'shops_desc':'Créez et gerez vos boutique',
            'my_products':'Mes produits',
            'my_products_desc':'Ajoutez vos produits et consultez la liste de vos produits.',
            'my_customers':'Mes clients',
            'my_customers_desc':'Gérez vos clients en les ajoutant à votre liste.',
            'coupons':'Coupons de reduction',
            'coupons_desc':'Générez des coupons de reductions pour vos clients.',
            'orders': 'Commnades',
            'orders_desc':'Gérez les commandes provenant de vos clients.',
            'name':'Nom de la boutique',
            'description':'Brève description de la boutique',
            'about':'À propos (description détaillée)',
            'image':'Logo de la boutique',
            'new_description':'Nouvelle boutique',
            'confirm_action':'Voulez-vous vraiment créer cette boutique',
            'shop_enable':'Activer',
            'shop_disable':'Désactiver',
            'delete':'Supprimer',
            'open':'Ouvrir',
            'products_shop':'Produits de la boutique',
            'modify':'Modifier les informations',
            'add_product_description':'Ajouter des produits à votre boutique',
            'list_products_shop':'Liste des produits à jouter',
            'empty_products':'Aucun produit sélectionné',
            'list_products_title':'Liste des produits de la boutique',
            'add_products':'Ajouter un produit',
            'remove':'Retirer de la liste',
            'update_shop_description':'Modifier les informations',
            'update_success_message':'Informations modifiées !',
            'update_error_message':'Echec de la modification !',
            'confirm_update':'Voulez-vous vraiment modifier ces informations ?',
            'add_action':'Les produits ont été ajouté à votre boutique',
            'error':'Erreur lors de l\'ajout des produits',
            'cancel':'Opération annulée',
            'confirm_action_add_products':'Voulez-vous vraiment ajouter ces produits à votre boutique ?',
            'store':'Boutique classique',
            'workshop':'Atelier',
            'type':'Type de boutique',
            'select_type':'Selectionnez le type',
            'customize': 'Personnaliser la Boutique',
            'config_sidebar': 'Position de la barre de navigation',
            'config_select_sidebar': 'Selectionner une position',
            'config_sidebar_top': 'HAUT',
            'config_sidebar_left': 'GAUCHE',
            'config_sidebar_right': 'DROIT',
            'config_sidebar_none': 'AUCUN',
            'config_views': 'Affichage des produits',
            'config_select_views': 'Selectionner une disposition',
            'config_views_grid': 'Grille',
            'config_views_list': 'Liste',
            'config_theme': 'Theme de la Boutique',
            'config_select_theme': 'Selectionner un Theme',
            'config_theme_default': 'Theme par defaut',
            'config_theme_dark': 'Theme en noir',
            'config_theme_other': 'Autre',
            'config_items_per_page': 'Nombre d\'article par page',
          },
          company:{
            'change_logo': 'Changer de logo',
            'change_stamp': 'Changer le cachet',
          },
          profilekyc:{
            'confirm_action': 'Voulez-vous vraiment envoyer ses informations',
          },
          previewInvoice:{
            'description':'Description',
            'quantity':'Quantité',
            'pu':'Prix unitaire',
            'total_price':'Prix total',
            'discount':'Réduction',
            'total':'TOTAL',
            'preview_description':'Confirmation de la facture',
            'confirm':'Confirmer',
          },
          coupon:{
            'new_description':'Ajouter un coupon de réduction',
            'type':'Type de coupon',
            'select_type':'Selectionnez le type de coupon',
            'type_percent':'Pourcentage',
            'type_fixed':'Montant fixe',
            'percent_label':'Pourcentage de la réduction',
            'fixed_label':'Montant de la réduction',
            'percent_placeholder':'Pourcentage',
            'fixed_placeholder':'Montant fixe',
            'action_new':'Créer un coupon',
            'confirm_action':'Voulez-vous vraiment ajouter ce coupon ?',
            'add_success_message':'Coupon ajouté !',
            'add_error_message':'Erreur lors de l\'ajout du coupon !',
            'for':'Définir la validité du coupon',
            'forLimited':'Utilisation limitée',
            'forUnlimited':'Utilisation illimitée',
            'times':'Nombre d\'utilisation',
            'coupon_title':'Liste des coupons de réduction',
            'empty':'Aucun coupon',
            'code':'Code',
            'type_coupon':'Type',
            'discount_value':'Réduction',
            'expired_at':'Date d\'expiration',
            'edit_coupon_description':'Modifier les informations',
            'usage':'Nombre d\'utilisation defini',
            'informations':'Information du coupon',
          },
          subscription:{
            'subscription_title':'Choisissez le meilleur forfait pour votre entreprise',
            'per_month':'par mois.',
            'buy':'Souscrire à ce forfait',
            'previous': 'Souscrire à un pack plus petit',
            'confirm_action': 'Voulez-vous vraiment souscrire au forfait {{name}} ?',
            'success':'Souscription au forfait effectué !',
            'error':'Echec de la souscription.',
            'details_subscription_title':'Détails du forfait en cours',
            'free':'GRATUIT',
            'noSms':'Aucun SMS',
            'shop':'boutique',
            'shops':'boutiques',
            'products':'produits',
            'coupons':'Coupons illimités',
            'noReward':'Aucune récompense',
            'reward':'Récompenses (par commande)',
            'normalAssist':'Assistance normale',
            'priorityAssist':'Assistance prioritaire',
            'premiumAssist':'Assistance premium',
            'realTimeAssist':'Assistance en temps réel',
            'com_level':'Commission niveau',
            'noFlyer':'Aucun flyer',
            'noPromote':'Aucun boost par mois',
            'promote':'jours de boost par mois',
            'monthlyReport':'Rapport d\'activité mensuel',
            'noReport':'Aucun rapport d\'activité',
            'weeklyReport':'Rapport d\'ativité hebdomadaire',
            'autoRenewal':'Renouvellement automatique',
            'successRenewal':'Renouvellement du forfait modifié avec succès !',
            'errorRenewal':'Une erreur s\'est produite lors de la modification du renouvellement du forfait !',
            'current':'Pack En Cours',
            'no_plan_msg':'Aucun pack en cours, veuillez souscrire a un pack svp !',
            'select_pack': 'Souscrire Ici',
            'upgrade_pack': 'Upgrade le pack',
            'renew': 'Renouveler le pack',
            'name': 'Pack',
            'expired_at': 'Date d\'expiration',
            'subscribe_at' : 'Date de Souscription',
            'emails': 'Mails restant',
            'sms': 'Sms restant',
            'available_coupons': 'Coupons restant',
          },
          order:{
            'confirm_title':'Confirmation',
            'confirm_text':'Êtes-vous sûr de vouloir accepter cette commande ?',
            'confirm_rejected_text':'Êtes-vous sûr de vouloir refuser cette commande ?',
            'yes':'Confirmer',
            'no':'Annuler',
            'accepted_alert':'Commande acceptée !',
            'rejected_alert':'Commande refusée !',
            'error':'Une erreur s\'est produite lors de l\'operation !',
            'reference':'Reférence',
            'customer_name':'Client',
            'amount':'Montant de la commande',
            'status':'statut',
            'list_title':'Liste des commandes',
            'inProgress':'En cours',
            'awaiting':'En attente',
            'rejected':'Réfusé',
            'paid':'Payé',
            'email':'Email du client',
            'name':'Nom du client',
            'date':'Date de la commande',
            'contact':'Contact du client',
            'informations':'Informations de la commande',
            'products':'Articles',
            'shipping':'Adresse de livraison',
            'payment_type':'Type de paiement',
            'at_delivery':'Paiement à la livraison',
            'prepayment':'Paiement anticipé',
            'shipping_amount': 'Montant de la livraison',
            'empty': 'Aucune commande',
            'delivery_date_label': 'Entrez la date de livraison de la commande',
            'required_date': 'Date de livraison requise',
            'date_delivery': 'Date de livraison',
          },
          payments:{
            title: 'Paiement',
            confirm_action: 'Voulez-vous collecter {{amount}} {{currency}} à partir du compte {{wallet}} ?',
            collect_title:'Collecter un paiement',
            mass_title:'Paiement de masse',
            'emit_title':'Emettre un paiement',
            'emit_desc':'Facilitez le processus d\'émission de paiements individualisés avec une solution rapide et fiable.',
            'mass_desc':'Gérez aisément les paiements de masse pour rémunérer plusieurs partenaires en toute simplicité.',
            'collect_desc':'Simplifiez la collecte de paiements de vos clients de manière efficace et sécurisée.',
            'code':'Code de paiement',
            'code_instruction':'Cliquer sur ce bouton pour obtenir le code',
            'requestOtp':'Obtenir code',
            'description':'Description',
            'otpSentMessage':'Le code a été envoyé dans votre boite mail !',
            'type':'Type de partenaire (bénéficiaire)',
            'choose_amount':'Montant à envoyer',
            'use_defined_amount':'Utiliser le montant défini pour chaque partenaire',
            'define_new_amount':'Définir un nouveau montant à envoyer à tout le monde',
            'confirm_action_mass': 'Voulez-vous vraiment effectuer ce paiement ?'
          },

          marketing:{
            'marketing_booster_title': 'Marketing booster',
            'channel_title': 'Mes canaux de diffusion',
            'channel_desc': 'Établissez vos canaux de diffusion et définissez la liste de clients associée.',
            'campaign_title': 'Mes campagnes',
            'campaign_desc': 'Optimisez votre stratégie marketing avec une campagne ciblée pour attirer et fidéliser vos clients.',
          },
          channel:{
            'channels_title': 'Canaux de diffusion',
            'new_channel': 'Nouveau canal',
            'customers_channel': 'Clients du canal',
            'edit': 'Modifier',
            'delete': 'Supprimer',
            'list_customers_title': 'Clients du canal',
            'empty_customer': 'Aucun client',
            'add_customers': 'Ajouter client(s)',
            'add_customers_description': 'Ajouter des clients au canal',
            'list_customer_channel': 'Liste des clients selectionnés',
            'confirm_action_add_customers': 'Voulez-vous vraiment ajouter ces clients à votre canal ?',
            'customer_error': 'Une erreur est survenue lors de l\'opération !',
            'customer_success': 'Client(s) ajouté(s) au canal!',
            'new_description': 'Ajouter un canal',
            'name': 'Nom du canal',
            'description': 'Description du canal',
            'confirm_action': 'Voulez-vous vraiment ajouter ce canal ?',
            'add_success_message': 'Cannal ajouté !',
            'add_error_message': 'Une erreur est survenue lors de l\'opération !',
            'remove': 'Rétirer',
            'update_channel_description':'Modifier les informations',
          },
          campaign:{
            'new_description': 'Nouvelle campagne',
            'name': 'Nom de la campagne',
            'type': 'Type de campagne',
            'template': 'Modèle',
            'message': 'Message de la campagne',
            'start_date': 'Date et heure du début',
            'channel': 'Canal de diffusion',
            'select_channel': 'Selectionnez le canal',
            'select_type': 'Selectionnez le type',
            'select_template': 'Selectionnez le modèle',
            'email': 'Email',
            'sms': 'SMS',
            'soleaspay': 'Soleaspay',
            'list_title': 'Liste des campagnes',
            'empty': 'Aucune campagne',
            'action_new': 'Nouvelle campagne',
            'confirm_action': 'Voulez-vous vraiment créer cette campagne ?',
            'add_error_message': 'Une erreur est survenue lors de l\'opération !',
            'add_success_message': 'Campagne créée !',
            'status': 'Statut',
            'edit': 'Modifier',
            'delete': 'Supprimer',
            'detail': 'Informations',
            'pending': 'En cours',
            'done': 'Effectué',
            'update_description':'Modifier les informations',
            'date': 'Date de création',
            'informations': 'Informations de la campagne',
            'update_campaign_description':'Modifier les informations',
            'default': 'Par défaut',
          },
          partner:{
            'name':'Nom du partenaire',
            'email':'Email du partenaire',
            'contact':'Contact du partenaire',
            'type':'Type de partenaire',
            'pay_amount':'Montant à payer',
            'cycle':'Cycle de paiement',
            'employee':'Employé',
            'supplier':'Fournisseur',
            'other':'Autre',
            'month':'Mois',
            'year':'Année',
            'quarter':'Trimestre',
            'semester':'Semestre',
            'new_description':'Ajouter un partenaire',
            'list_title':'Liste des partenaires',
            'empty':'Aucun partenaire',
            'action_new':'Ajouter partenaire',
            'partners_title':'Mes partenaires',
            'partners_desc':'Repertoriez vos partenaires',
            'confirm_action':'Voulez-vous vraiment ajouter ce partenaire ?',
            'select_type':'Choisissez le type',
            'select_cycle':'Choisissez le cycle',
            'edit':'Modifier',
            'detail':'Informations',
            'informations':'Informations du partenaire',
            'update_partner_description':'Modifier les informations du partenaire',
            'import_csv':'Importer liste',
          },
          import:{
            'import_partner_title':'Impoter la liste des partenaires',
            'import_customer_title':'Importer la liste des clients',
            'ensure_correct_model':'Veuillez vous assurer d\'utiliser le bon modèle de fichier. Tout autre modèle ne sera pas pris en charge.Pour télécharger le modèle, veuillez cliquer sur le bouton de "Télécharger" en haut à droite.',
            'download':'Télécharger',
            'choose':'Choisir le fichier à importer',
            'upload_success': 'Liste importée avec succès !',
            'upload_failed': 'L\'importation à echoué !',
            'file_required': 'Fichier à importer requis',
          }


    }
}